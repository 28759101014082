import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  Column,
  FullWidthGraphic,
  Graphic1,
  Graphic1Wrapper,
  GraphicWrapper,
  HeaderContainer,
  HeaderLarge,
  MainContainer,
  Row,
  SectionHeader,
  SubSectionHeader,
  Subheader,
  Text,
  AppButtonContainer,
  AppButton,
  Row1,
  Graphic,
  IframeWrapper,
  EmailSignUpWrapper,
  MobileContent,
  DesktopContent,
} from "../page-components/MobileApp/styles";
import useWindowDimensions from "../utils/windowSize";
import { SiteWrapper } from "../components/SiteWrapper";
import { ACTIVE_PAGE } from "../components/nav/utils";
import Seo from "../components/seo";
import AppleBW from "../assets/images/AppleBW.png";
import AndroidBW from "../assets/images/AndroidBW.png";
import Apple from "../assets/images/Apple.png";
import Google from "../assets/images/Google.png";
import EmailSignUp from "../page-components/LandingPage/components/EmailSignUp/EmailSignUp";
import { Android, ArrowForward } from "@mui/icons-material";
import Skeleton from "@mui/material/Skeleton";
export default function MobileAppPage() {
  const { width } = useWindowDimensions();
  const columnRef = useRef<HTMLDivElement>(null);
  const graphicRef = useRef<HTMLDivElement>(null);
  const lastColumnRef = useRef<HTMLDivElement>(null);
  const lastGraphicRef = useRef<HTMLDivElement>(null);
  const [columnHeight, setColumnHeight] = useState(0);
  const [lastColumnHeight, setLastColumnHeight] = useState(0);

  const CTAButton: React.FC<{ title: string }> = ({ title }) => (
    <button
      style={{
        position: "absolute",
        zIndex: 9999,
        backgroundColor: "#FFB310",
        color: "white",
        padding: "12px 24px",
        border: "none",
        borderRadius: "4px",
        fontWeight: "bold",
        cursor: "pointer",
        width: "350px",
        fontSize: "2rem",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        justifyContent: "center",
      }}
      onClick={() => (window.location.href = "/contact")}
    >
      {title} <ArrowForward style={{ fontSize: 20 }} />
    </button>
  );

  const SVGWithButton: React.FC<{
    src: string;
    buttonPosition: string;
    title: string;
    showAppStoreButtons?: boolean;
  }> = ({ src, buttonPosition, title, showAppStoreButtons }) => {
    const containerStyle = {
      position: "relative" as const,
      display: "block",
      width: "100%",
    };

    const getButtonStyle = () => {
      const baseStyle = {
        position: "absolute" as const,
        zIndex: 9999,
      };

      switch (buttonPosition) {
        case "1":
          return {
            ...baseStyle,
            left: "18%",
            bottom: "20%",
          };
        case "2":
          return {
            ...baseStyle,
            left: "25%",
            bottom: "15%",
          };
        case "3":
          return {
            ...baseStyle,
            left: "57%",
            top: "20%",
            transform: "translateX(-50%)",
          };
        case "4":
          return {
            ...baseStyle,
            right: "42%",
            bottom: "10%",
          };
        default:
          return baseStyle;
      }
    };

    const appStoreButtonsStyle = {
      position: "absolute" as const,
      display: "flex",
      gap: "10px",
      zIndex: 9999,
      left: "18%",
      bottom: "25%", // Positioned above the CTA button
    };

    const storeButtonStyle = {
      width: "168px",
      height: "auto",
      cursor: "pointer",
      borderRadius: "5px",
    };

    return (
      <div style={containerStyle}>
        <img src={src} style={{ width: "100%", display: "block" }} alt="" />
        <div style={getButtonStyle()}>
          <CTAButton title={title} />
        </div>
        {showAppStoreButtons && (
          <div style={appStoreButtonsStyle}>
            <a
              href="https://apps.apple.com/us/app/robotic-imaging/id1546776636"
              id="AppleAppStore"
            >
              <img
                src={AppleBW}
                alt="Download on Apple App store"
                style={storeButtonStyle}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.roboticimaging&pcampaignid=web_share"
              id="GooglePlayStore"
            >
              <img
                src={AndroidBW}
                alt="Download on Google Play store"
                style={storeButtonStyle}
              />
            </a>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (columnRef.current) {
      setColumnHeight(columnRef.current.clientHeight);
    }
    if (lastColumnRef.current) {
      setLastColumnHeight(lastColumnRef.current.clientHeight);
    }
  }, [width]);

  useEffect(() => {
    const handleResize = () => {
      if (columnRef.current) {
        setColumnHeight(columnRef.current.clientHeight);
      }
      if (lastColumnRef.current) {
        setLastColumnHeight(lastColumnRef.current.clientHeight);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.MOBILEAPP}>
      <Seo
        title="Mobile App"
        description="Get a precise, as-built building model of real estate quickly. We deliver accurate point cloud data, 2D CAD, and 3D BIM at competitive prices."
      />
      <header className="site__header"></header>
      <MobileContent>
        <MainContainer>
          <Row1>
            <HeaderContainer>
              <HeaderLarge>Effortless</HeaderLarge>
              <HeaderLarge>Documentation</HeaderLarge>
              <EmailSignUpWrapper>
                <EmailSignUp />
              </EmailSignUpWrapper>
              <Subheader>Download Today</Subheader>
              <AppButtonContainer>
                <a
                  href="https://apps.apple.com/us/app/robotic-imaging/id1546776636"
                  id="AppleAppStore"
                >
                  <AppButton src={Apple} alt="Download on Apple App store" />
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=com.roboticimaging&pcampaignid=web_share"
                  id="GooglePlayStore"
                >
                  <AppButton src={Google} alt="Download on Google Play store" />
                </a>
              </AppButtonContainer>
            </HeaderContainer>
            <Graphic1Wrapper>
              <Graphic1
                src={
                  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FMobileApp%2Frobo%20png%20mobile.png?alt=media&token=e85acdae-7325-49f0-b604-c6e65068e90b"
                }
                alt="download on app store"
              />
            </Graphic1Wrapper>
          </Row1>
          <SectionHeader>
            Experience the future of field operations with robotic imaging
          </SectionHeader>
          <Row>
            <Column ref={columnRef}>
              <Text>
                Robotic Imaging, a leading innovator in digital field management
                and documentation, proudly presents its groundbreaking mobile
                application. Available for both iOS and Android users, this
                state-of-the-art app is designed to transform your field
                operations by offering comprehensive tools for project
                management, documentation, and collaboration – all from the
                convenience of your mobile device.
              </Text>
              <SubSectionHeader>Streamlined Field Management</SubSectionHeader>
              <Text>
                Our mobile app features a user-friendly interface and powerful
                tools that empower professionals to manage projects with
                unprecedented ease and efficiency. Discover how Robotic Imaging
                can revolutionize your workflow:
              </Text>
              <SubSectionHeader>
                Stay connected anywhere, anytime
              </SubSectionHeader>
              <Text>
                Stay in control of your projects, files, and data on any device,
                at any time. Our mobile app ensures you're always connected, no
                matter where you are.
              </Text>
            </Column>
            <GraphicWrapper
              ref={graphicRef}
              style={{ height: columnHeight, justifyContent: "center" }}
            >
              <Graphic
                src={
                  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FMobileApp%2FMobile%20App%20Graphic%204.png?alt=media&token=d2b680f6-6d7d-448d-89fa-63f64316a4e6"
                }
                alt="example graphic"
              />
            </GraphicWrapper>
          </Row>
          <Row style={{ marginTop: 22 }}>
            <Column>
              <SubSectionHeader>Seamless Field Solutions</SubSectionHeader>
              <Text>
                <ul style={{ maxWidth: "90%" }}>
                  <li>
                    On-Site Capture Tools: Capture data and images directly from
                    your device
                  </li>
                  <li>
                    Manage Documents and Photos: Upload and organize documents
                    and photos
                  </li>
                  <li>
                    Simplify Project Management: Create or edit projects with a
                    few taps
                  </li>
                  <li>
                    Detailed Documentation: Use forms for precise project
                    documentation
                  </li>
                  <li>
                    Enhanced Collaboration: Share project details, photos, and
                    documents instantly
                  </li>
                </ul>
              </Text>
            </Column>
            <Column>
              <SubSectionHeader>Why Choose Robotic Imaging?</SubSectionHeader>
              <Text>
                Choosing Robotic Imaging means opting for unparalleled
                convenience, accuracy, and efficiency in managing field
                operations. Our app is designed to:
                <ul style={{ maxWidth: "80%" }}>
                  <li>Keep all project-related files organized</li>
                  <li>Enhance productivity by streamlining workflows</li>
                  <li>Improve the accuracy of project documentation</li>
                  <li>Boost collaboration amongst team members</li>
                </ul>
              </Text>
            </Column>
          </Row>
          <SectionHeader>
            Discover more: Watch our app in action today!
          </SectionHeader>
          <Row>
            <Column ref={lastColumnRef}>
              <Text>
                Curious to see your mobile app's powerful features in action?
                Check out our Youtube video advertisement! Watch how Robotic
                Imaging transforms field operations, making project management,
                documentation, and collaboration easier than ever. Click the
                link below to explore the full potential of our app and see why
                professionals are making the switch.
              </Text>
              <IframeWrapper>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/LLIoUqWkXa8?si=3-AEKWlA6aesnaLY"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </IframeWrapper>
            </Column>
            <GraphicWrapper
              ref={lastGraphicRef}
              style={{ height: lastColumnHeight }}
            >
              <Graphic
                src={
                  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FMobileApp%2FMobile%20App%20Store%20Graphic%201.png?alt=media&token=265e8a8c-b4af-4706-8b69-d34910c19d6c"
                }
                alt="right column image"
              />
            </GraphicWrapper>
          </Row>
        </MainContainer>
      </MobileContent>
      <DesktopContent>
        <MainContainer>
          <SVGWithButton
            src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FMobileApp%2FCopy%20of%20Copy%20of%20XSPECS%20-%20Robotic%20Imaging%20Deck%20(Website%20Services%20Page).svg?alt=media&token=457bb182-9459-42b7-9bdd-97284459098f"
            buttonPosition="1"
            title={"Get in touch with us today"}
            showAppStoreButtons={true} // Add this prop
          />

          <SectionHeader>
            Experience the future of field operations with robotic imaging
          </SectionHeader>
          <Row>
            <Column ref={columnRef}>
              <Text>
                Robotic Imaging, a leading innovator in digital field management
                and documentation, proudly presents its groundbreaking mobile
                application. Available for both iOS and Android users, this
                state-of-the-art app is designed to transform your field
                operations by offering comprehensive tools for project
                management, documentation, and collaboration – all from the
                convenience of your mobile device.
              </Text>
              <SubSectionHeader>Streamlined Field Management</SubSectionHeader>
              <Text>
                Our mobile app features a user-friendly interface and powerful
                tools that empower professionals to manage projects with
                unprecedented ease and efficiency. Discover how Robotic Imaging
                can revolutionize your workflow:
              </Text>
              <SubSectionHeader>
                Stay connected anywhere, anytime
              </SubSectionHeader>
              <Text>
                Stay in control of your projects, files, and data on any device,
                at any time. Our mobile app ensures you're always connected, no
                matter where you are.
              </Text>
            </Column>
            <GraphicWrapper
              ref={graphicRef}
              style={{ height: columnHeight, justifyContent: "center" }}
            >
              <Graphic
                src={
                  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FMobileApp%2FMobile%20App%20Graphic%204.png?alt=media&token=d2b680f6-6d7d-448d-89fa-63f64316a4e6"
                }
                alt="example graphic"
              />
            </GraphicWrapper>
          </Row>
          <SVGWithButton
            buttonPosition="2"
            src={
              "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FMobileApp%2FPhoto%20reporting%20with%20the%20robotic%20imaging%20mobile%20app%20svg%20(1).svg?alt=media&token=349a5c5f-e2c1-422c-861c-804763adc211"
            }
            title={"Find out how we can help"}
          />
          <SectionHeader>
            Discover more: Watch our app in action today!
          </SectionHeader>
          <Row>
            <Column ref={lastColumnRef}>
              <Text>
                Curious to see your mobile app's powerful features in action?
                Check out our Youtube video advertisement! Watch how Robotic
                Imaging transforms field operations, making project management,
                documentation, and collaboration easier than ever. Click the
                link below to explore the full potential of our app and see why
                professionals are making the switch.
              </Text>
              <IframeWrapper>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/LLIoUqWkXa8?si=3-AEKWlA6aesnaLY"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </IframeWrapper>
            </Column>
            <GraphicWrapper
              ref={lastGraphicRef}
              style={{ height: lastColumnHeight }}
            >
              <Graphic
                src={
                  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FMobileApp%2FMobile%20App%20Store%20Graphic%201.png?alt=media&token=265e8a8c-b4af-4706-8b69-d34910c19d6c"
                }
                alt="right column image"
              />
            </GraphicWrapper>
          </Row>
          <SVGWithButton
            buttonPosition="3"
            src={
              "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FMobileApp%2FCustom%20Report%20Production%20svg%20roboticimaging%20(1).svg?alt=media&token=2e558980-c1bb-4f07-bd46-2faa0eb175bf"
            }
            title={"Find out how we can help"}
          />
          <Row1>
            <HeaderContainer>
              <HeaderLarge>Effortless</HeaderLarge>
              <HeaderLarge>Documentation</HeaderLarge>
              <EmailSignUpWrapper>
                <EmailSignUp />
              </EmailSignUpWrapper>
              <Subheader>Download Today</Subheader>
              <AppButtonContainer>
                <a
                  href="https://apps.apple.com/us/app/robotic-imaging/id1546776636"
                  id="AppleAppStore"
                >
                  <AppButton src={Apple} alt="Download on Apple App store" />
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=com.roboticimaging&pcampaignid=web_share"
                  id="GooglePlayStore"
                >
                  <AppButton src={Google} alt="Download on Google Play store" />
                </a>
              </AppButtonContainer>
            </HeaderContainer>
            <Graphic1Wrapper>
              <Graphic1
                src={
                  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FMobileApp%2Frobo%20png%20mobile.png?alt=media&token=e85acdae-7325-49f0-b604-c6e65068e90b"
                }
                alt="download on app store"
              />
            </Graphic1Wrapper>
          </Row1>
          <SVGWithButton
            buttonPosition="4"
            src={
              "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FMobileApp%2FRobotic%20Imaging%20Standard%20Survey%20Equipment.svg?alt=media&token=6c57a915-acaa-4d28-b088-df735891fb93"
            }
            title={"Learn more about us"}
          />
        </MainContainer>
      </DesktopContent>
    </SiteWrapper>
  );
}
